export const OKTA_GROUPS = {
  STREAMING_ADMIN: 'Streaming Admin',
  STREAMING_CUSTOMER_SUPPORT: 'Streaming Customer Support',
  STREAMING_DEVELOPER: 'Streaming Developer',
  STREAMING_INFOSEC: 'Streaming Infosec',
  STREAMING_MEDIA_OPERATORS: 'Streaming Media Operators',
  STREAMING_POLICY_ADMIN: 'Streaming Policy Admin',
  STREAMING_POLICY_MANAGER: 'Streaming Policy Manager',
  STREAMING_PRODUCT_MANAGER: 'Streaming Product Manager',
  STREAMING_STREAMING_LOGGER: 'Streaming Logger',
  STREAMING_VIEWER: 'Streaming Viewer',
} as const;

export type OktaGroup = (typeof OKTA_GROUPS)[keyof typeof OKTA_GROUPS];

export const checkGroupAuth = (allowedGroups: string[], userGroups: OktaGroup[]) => {
  if (allowedGroups.length === 0) return true;

  return allowedGroups.some((group) => userGroups.includes(group as OktaGroup));
};
